<template>
  <div>
    <CCard>
      <CCardHeader class="d-flex flex-wrap align-items-center">
        <SMessageWallet :data="wallet" />
        <TSpinner :loading="fetching" />
        <div class="ml-auto">
          <TButtonRefresh
            @click="
              $store.dispatch('accounting.wallets_suppliers.detail.fetch', id)
            "
          />
        </div>
      </CCardHeader>
      <CCardBody>
        <CRow>
          <CCol md="3">
            <CCallout color="info">
              <TMessage content="Supplier" bold color="info" uppercase />
              <TMessage :content="wallet.object ? wallet.object.name : '???'" class="h5" noTranslate />
            </CCallout>
          </CCol>
          <CCol md="3">
            <CCallout color="info">
              <TMessage content="Currency" bold color="info" uppercase />
              <TMessage :content="wallet.currency_id" class="h5" noTranslate />
            </CCallout>
          </CCol>
          <CCol md="3">
            <CCallout color="primary">
              <TMessage content="Balance" bold color="primary" uppercase />
              <TMessageMoney :amount="wallet.balance" class="h5" />
            </CCallout>
          </CCol>
          <CCol md="3">
            <CCallout color="primary">
              <TMessage
                content="Available payment"
                bold
                color="primary"
                uppercase
              />
              <TMessageMoney
                :amount="wallet.amount_available_for_payment"
                class="h5"
              />
            </CCallout>
          </CCol>
        </CRow>
      </CCardBody>
    </CCard>
    <TableTransactions
      v-if="wallet.id"
      store="accounting.transactions"
      class="mb-4"
      :default_filter="default_filter"
      :key="`table-transactions-${wallet.id}`"
    />
  </div>
</template>

<script>
import TableTransactions from "../../../components/TableTransactions.vue";

export default {
  components: { TableTransactions },
  created() {
    this.$store.dispatch("accounting.wallets_suppliers.detail.fetch", this.id);
  },
  computed: {
    wallet() {
      return this.$store.getters["accounting.wallets_suppliers.detail"];
    },
    fetching() {
      return this.$store.getters[
        "accounting.wallets_suppliers.detail.fetching"
      ];
    },
    id() {
      return this.$route.params.id;
    },
    default_filter() {
      return {
        "filter[transactionable_type]": this.wallet.walletable_type,
        "filter[transactionable_id]": this.wallet.walletable_id,
        "filter[currency_id]": this.wallet.currency_id,
      };
    },
  },
};
</script>
